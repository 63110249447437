<div class="text-center mt-5" *ngIf="loading">
  <div class="spinner-border text-secondary">
      <span class="sr-only">{{'APP.LOADING' | translate}}</span>
  </div>
</div>
<div *ngIf="!loading">
  <div *ngIf="project">
    <h2 *ngIf="project.hasFolders">{{'DOCUMENTS.PROJECTFORFOLDER' | translate:{'projectname':project.displayName} }}</h2>
    <h2 *ngIf="!project.hasFolders">{{'DOCUMENTS.PROJECTFORCUSTOMER' | translate:{'projectname':project.displayName,
      'subproject': project.subProject} }}</h2>
    <div class="input-group input-group-ih me-2">
      <div class="input-group-text input-group-text-ih">{{'DOCUMENTS.UPLOADFILE' | translate}}:&nbsp;</div>
      <input type="button" id="documentsSelectFileButton" [value]="'DOCUMENTS.SELECTFILE' | translate"
        class="btn btn-primary" onclick="document.getElementById('documentsSelectFile').click();" />
      <input type="file" class="file-input" id="documentsSelectFile" name="documentsSelectFile" style="display:none;"
        (change)="onFileSelected($event)" #fileUpload [ngbTooltip]="'DOCUMENTS.SELECTFILE' | translate">
      <div id="documentNoFile" *ngIf="fileName.length === 0" class="input-group-text input-group-text-ih">{{'DOCUMENTS.NOFILE' | translate}}</div>
      <div id="documentFile" *ngIf="fileName" class="input-group-text input-group-text-ih">{{fileName}}</div>
      <button (click)="upload()" class="btn btn-success btn-icon-ih" [ngbTooltip]="'DOCUMENTS.UPLOAD' | translate"
        id="documentsUploadFile" name="documentsUploadFile">
        <fa-icon [icon]="uploadIcon"></fa-icon>
      </button>
    </div>
    <div class="row" *ngIf="project.hasFolders">
      <div class="col-md-auto">
        <h6 id="documentsPathBreadcrumbs" class="mt-3 breadcrumb-dark-ih">
          <span>{{'DOCUMENTS.PATH' | translate}}:</span>
          <a id="breadcrumbLink-{{project.displayName}}" routerLink="" (click)="onSelectRoot(project)">&nbsp;{{project.displayName}}&nbsp;/</a>
          <a id="breadcrumbLink-{{document.displayName}}" *ngFor="let document of path" routerLink=""
            (click)="onSelectPath(project, document)">
            &nbsp;{{document.displayName}}&nbsp;/</a>
        </h6>
      </div>
      <div class="col-sm">
        <button id="newFolderButton" name="newFolderButton" (click)="newFolder()"
          class="float-end btn btn-success btn btn-icon-ih" [ngbTooltip]="'DOCUMENTS.NEWFOLDER' | translate">
          <fa-icon [icon]="folderPlusIcon"></fa-icon>
        </button>
      </div>
    </div>
    <table id="documentTable" class="table table-ih table-striped documents" [ngbTooltip]="'SORTHINT' | translate" container="body">
      <thead class=".thead-light">
        <tr>
          <th scope="col" sortabledocument="displayName" (sortDocument)="onSort($event)">{{'DOCUMENTS.FILENAME'
            |translate}}</th>
          <th scope="col" sortabledocument="modificationDate" (sortDocument)="onSort($event)">
            {{'DOCUMENTS.MODIFICATIONDATE' |translate}}</th>
          <th scope="col">{{'DOCUMENTS.ACTION' |translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr id="document-row-{{document.displayName}}" *ngFor="let document of documents">
          <td *ngIf="document.isFolder" id="document-cell-filename-{{document.displayName}}">
            <fa-icon [icon]="folderIcon"></fa-icon>
            <a id="document-link-{{document.displayName}}" routerLink=""
              (click)="onClick(document)">&nbsp;{{document.displayName}}</a>
          </td>
          <td *ngIf="isPdf(document)" id="document-cell-filename-{{document.displayName}}">
            <fa-icon [icon]="pdfFileIcon"></fa-icon><a routerLink=""
              (click)="onClick(document)">&nbsp;{{document.displayName}}</a>
          </td>
          <td *ngIf="isImage(document)" id="document-cell-filename-{{document.displayName}}">
            <fa-icon [icon]="imageFileIcon"></fa-icon><a routerLink=""
              (click)="onClick(document)">&nbsp;{{document.displayName}}</a>
          </td>
          <td *ngIf="isAny(document)" id="document-cell-filename-{{document.displayName}}">
            <fa-icon [icon]="fileIcon"></fa-icon><a routerLink=""
              (click)="onClick(document)">&nbsp;{{document.displayName}}</a>
          </td>
          <td id="document-cell-modificationdate-{{document.displayName}}-{{document.modificationDate}}">
            {{document.modificationDate | localizedDate}}
          </td>
          <td>
            <div *ngIf="document.isFolder">
              <div class="btn-group">
                <button id="document-rename-btn-{{document.displayName}}" (click)="rename(document)"
                  class="btn btn-success btn-sm" [ngbTooltip]="'DOCUMENTS.RENAME' | translate">
                  <fa-icon [icon]="renameIcon"></fa-icon>
                </button>
                <button id="document-move-btn-{{document.displayName}}" (click)="move(document)"
                  class="btn btn-success btn-sm" [ngbTooltip]="'DOCUMENTS.MOVE' | translate">
                  <fa-icon [icon]="moveIcon"></fa-icon>
                </button>
                <button id="document-delete-btn-{{document.displayName}}" (click)="confirmDeleteFolder(document)"
                  class="btn btn-danger btn-sm" [ngbTooltip]="'DOCUMENTS.DELETE' | translate">
                  <fa-icon [icon]="deleteIcon"></fa-icon>
                </button>

              </div>
            </div>
            <div *ngIf="project.hasFolders">
              <div *ngIf="!document.isFolder">
                <div class="btn-group">
                  <button id="document-rename-btn-{{document.displayName}}" (click)="rename(document)"
                    class="btn btn-success btn-sm" [ngbTooltip]="'DOCUMENTS.RENAME' | translate">
                    <fa-icon [icon]="renameIcon"></fa-icon>
                  </button>
                  <button id="document-move-btn-{{document.displayName}}" (click)="move(document)"
                    class="btn btn-success btn-sm" [ngbTooltip]="'DOCUMENTS.MOVE' | translate">
                    <fa-icon [icon]="moveIcon"></fa-icon>
                  </button>
                  <button id="document-delete-btn-{{document.displayName}}" (click)="confirmDelete(document)"
                    class="btn btn-danger btn-sm" [ngbTooltip]="'DOCUMENTS.DELETE' | translate">
                    <fa-icon [icon]="deleteIcon"></fa-icon>
                  </button>
                  <button id="document-download-btn-{{document.displayName}}" (click)="download(document)"
                    class="btn btn-success btn-sm" [ngbTooltip]="'DOCUMENTS.DOWNLOAD' | translate">
                    <fa-icon [icon]="downloadIcon"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="!project.hasFolders">
              <div class="btn-group">
                <button id="document-delete-btn-{{document.displayName}}" (click)="confirmDelete(document)"
                  class="btn btn-danger btn-sm" [ngbTooltip]="'DOCUMENTS.DELETE' | translate">
                  <fa-icon [icon]="deleteIcon"></fa-icon>
                </button>
                <button id="document-download-btn-{{document.displayName}}" (click)="download(document)"
                  class="btn btn-success btn-sm" [ngbTooltip]="'DOCUMENTS.DOWNLOAD' | translate">
                  <fa-icon [icon]="downloadIcon"></fa-icon>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
</div>
</div>