<div class="modal-header modal-header-ih">
    <h4 class="modal-title modal-title-ih" data-cy="document-move-dialog-title">{{title}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('CROSS_CLICK')"></button>
  </div>
  <div class="modal-body modal-body-ih">
    <div *ngIf="documents">
      <div class="col-md-auto">
        <h5 id="moveDocumentsPathBreadcrumbs" class="mt-3">
          <div *ngIf="path.length > 0">
            <span id="breadcrumb-{{document.displayName}}" *ngFor="let document of path">
              &nbsp;{{document.displayName}}&nbsp;/</span>
          </div>
          <div *ngIf="path.length === 0">
            <span id="breadcrumb-root">/</span>
          </div>
        </h5>
      </div>
        <table id="moveDocumentTable" class="table table-ih table-striped moveDocuments">
            <thead class=".thead-light">
              <tr>
                <th scope="col" class="col-3">{{selecttarget}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="path.length > 0" id="moveDocument-row-up">
                <td id="moveDocument-up-cellname">
                  <fa-icon [icon]="levelUpIcon"></fa-icon>
                  <a id="moveDocument-link-up" routerLink=""
                    (click)="onClick(undefined)">&nbsp;..</a>
                </td>
              </tr>
              <tr id="moveDocument-row-{{document.displayName}}" *ngFor="let document of documents">
                <td *ngIf="document.isFolder && !toMoveIsSame(document)" id="moveDocument-cell-filename-{{document.displayName}}">
                  <fa-icon [icon]="folderIcon"></fa-icon>
                  <a id="moveDocument-link-{{document.displayName}}" routerLink=""
                    (click)="onClick(document)">&nbsp;{{document.displayName}}</a>
                </td>
                <td *ngIf="document.isFolder && toMoveIsSame(document)" id="moveDocument-cell-filename-{{document.displayName}}">
                  <fa-icon [icon]="folderIcon"></fa-icon>
                  {{document.displayName}}
                </td>              
              </tr>
            </tbody>
          </table>
    </div>
    <div class="modal-footer modal-footer-ih">
      <button type="button" class="btn btn-outline-dark submit-ih"
        (click)="activeModal.dismiss('CANCEL_CLICK')" data-cy="cancel-document-move-button">{{cancelButtonText}}</button>
      <button type="button" class="btn btn-outline-dark submit-ih"
        (click)="activeModal.close('OK_CLICK')" data-cy="ok-document-move-button">{{okButtonText}}</button>
    </div>
  </div>
