import { Injectable } from '@angular/core';
import { _Object } from '@aws-sdk/client-s3';
import { LoggingService } from '@inspiring-health/mea-commons';
import { from, Observable } from 'rxjs';
import { STUDIES } from '../components/documents/mock-studydocs';
import { Document } from '../models/document.model';
import { Project } from '../models/project.model';
import { S3Service } from './s3.service';


@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private objKeyRegexp: RegExp = /^[a-zA-Z0-9!\-_.*'()&$+ ]+$/g

  constructor(private loggingService: LoggingService, private s3Service: S3Service) { }

  retrieveStudies(project?: Project): Document[] {
    return STUDIES;
  }

  isValidObjectKey(objKey: string): boolean {
    this.log("isValidObjectKey => param " + objKey)
    if (objKey.length > 1024) {
      this.log("isValidObjectKey => objKey to long; return false")
      return false
    }
    if (objKey.length === 0) {
      this.log("isValidObjectKey => objKey is 0; return false")
      return false
    }
    let match : RegExpMatchArray | null = objKey.match(this.objKeyRegexp)
    if (!match) {
      this.log("isValidObjectKey => objKey does not match valid characters; return false")
      return false
    }
    this.log("isValidObjectKey => return true")
    return true
  }

  downloadDokument(project: Project, doc: Document): Promise<ReadableStream> {
    this.log("downloadDokument")
    const projectName: string = project.relatedGroup
    const fileName: string = doc.fileName;
    return this.s3Service.getObject(projectName, fileName).then(objectCommandOutput => {
      this.log("downloadDokument => return body: " + objectCommandOutput.Body)
      return objectCommandOutput.Body;
    })
  }

  uploadDokument(level: number, project: Project, p: string, file: File): Observable<any> {
    this.log("uploadDokument => from level " + level + " for project " + project.name + " and path " + p + " and file " + file.name);
    const projectName: string = project.relatedGroup
    let path: string;
    if (project.hasFolders) path = p;
    else path = project.subProject;
    return from(this.s3Service.putObject(projectName, path, file).then(objectsCommandOutput => {
      return this.objectsCommandOutputToDocumentsArray(level, path, objectsCommandOutput, project, false);
    }).catch(e => this.logError("uploadDokument => " + e)));
  }

  copyDocument(level: number, project: Project, sourceFileName: string, destPath:string, destName: string): Observable<any> {
    this.log("copyDokument => with name " + sourceFileName + " from level " + level + " for project " + project.name + " to folder " + destPath + " with name " + destName)
    const projectName: string = project.relatedGroup
    //Cut last / if exist, because s3 methods don't like it
    if (destPath.endsWith('/')) destPath = destPath.substring(0, destPath.length-1)
    return from(this.s3Service.copyObject(projectName, sourceFileName, destPath, destName).then(objectsCommandOutput => {
      return this.objectsCommandOutputToDocumentsArray(level, this.retrieveParentDir(sourceFileName), objectsCommandOutput, project, false);
    }).catch(e => this.logError("copyDokument => " + e)));
  }

  createFolder(level: number, project: Project, doc: Document): Observable<any> {
    this.log("createFolder => from level " + level + " for project " + project.name + " and name " + doc.fileName);
    const projectName: string = project.relatedGroup
    let folderName: string = doc.fileName;
    //Cut last / because PutObject in S3 adds one
    if (folderName.endsWith('/')) folderName = folderName.substring(0, folderName.length-1)
    return from(this.s3Service.putObject(projectName, folderName).then(objectsCommandOutput => {
      return this.objectsCommandOutputToDocumentsArray(level, this.retrieveParentDir(folderName), objectsCommandOutput, project, false);
    }).catch(e => this.logError("createFolder => " + e)));
  }

  deleteDocument(level: number, project: Project, doc: Document): Observable<any> {
    this.log("deleteDocument => from level " + level + " for project " + project.name + " and document " + doc.displayName);
    const projectName: string = project.relatedGroup
    let path: string;
    let folder: string;
    let fileName: string;
    if (project.hasFolders && doc.isFolder) {
      //cut last '/'
      path = doc.fileName.substring(0, doc.fileName.lastIndexOf("/"));
      folder = this.retrieveParentDir(path)
      fileName = "";
    } else if (project.hasFolders && !doc.isFolder) {
      //cut file name
      path = doc.fileName.substring(0, doc.fileName.lastIndexOf("/"));
      folder = path
      fileName = doc.displayName;
    }
    else //without folders but sub projects
    {
      path = project.subProject;
      fileName = doc.displayName;
    }
    this.log("deleteDocument => in path " + path);
    return from(this.s3Service.deleteObject(projectName, path, fileName).then(objectsCommandOutput => {
      return this.objectsCommandOutputToDocumentsArray(level, folder, objectsCommandOutput, project, false);
    }).catch(e => this.logError("deleteDocument => " + e)));
  }

  retrieveDocuments(level: number, project: Project, folder: string, all: boolean): Observable<any> {
    this.log("retrieveDocuments => from level " + level + " for project " + project.name + " and folder " + folder);
    let projectName: string = project.relatedGroup
    const prefix: string = this.cutLastSlash(folder);
    return from(this.s3Service.getObjects(projectName, prefix).then(objectsCommandOutput => {
      return this.objectsCommandOutputToDocumentsArray(level, prefix, objectsCommandOutput, project, all);
    }).catch(e => this.logError("retrieveDocuments => can't getObjectsForFolder: " + e)));
  }

  folderHasContent(project: Project, folder: Document): Observable<boolean> {
    this.log("folderHasContent => for project " + project.name + " folder " + folder.fileName);
    let projectName: string = project.relatedGroup
    const prefix: string = folder.fileName;
    return from(this.s3Service.getObjects(projectName, prefix).then(objectsCommandOutput => {
      const objectsArray: _Object[] = objectsCommandOutput.Contents;
      //Folder itself is in the array
      if (objectsArray && objectsArray.length > 1) {
        return true;
      } else {
        return false;
      }
    }).catch(e => {
      this.logError("folderHasContent => can't getObjectsForFolder: " + e);
      return true;
    }));
  }

  private objectsCommandOutputToDocumentsArray(level: number, folder: string, objectsCommandOutput: any, project: Project, all: boolean): Document[] {
    let documentsArray: Document[] = [];
    const objectsArray: _Object[] = objectsCommandOutput.Contents;
    if (objectsArray && objectsArray.length > 0) {
      this.log("objectsCommandOutputToDocumentsArray => Number of objects is: " + objectsArray.length);
      if (project.hasFolders) {
        documentsArray = this.makeDocumentsArrayForFolders(level, folder, objectsArray, all);
      } else {
        documentsArray = this.makeDocumentsArrayForSubProjects(objectsArray, project);
      }
    }
    return documentsArray;
  }

  private makeDocumentsArrayForSubProjects(objectsArray: _Object[], project: Project): Document[] {
    let docArray: Document[] = [];
    const now: Date = new Date();
    objectsArray.forEach(object => {
      this.log("makeDocumentsArrayForSubProjects => Object key is: " + object.Key);
      //Check if object is not only a folder
      if (object.Key !== project.subProject + '/') {
        let fullFileName = "unknown";
        let name: string = "unknown";
        if (object.Key !== undefined) {
          fullFileName = object.Key;
          name = fullFileName.substring(fullFileName.lastIndexOf('/') + 1, fullFileName.length);
        }
        let lastModified: Date = now;
        if (object.LastModified !== undefined) {
          lastModified = object.LastModified;
        }
        const doc: Document = {
          displayName: name,
          fileName: fullFileName,
          isFolder: false,
          level: 0,
          modificationDate: lastModified
        }
        docArray.push(doc);
      } else {
        this.log("makeDocumentsArrayForSubProjects => Object is folder with name: " + object.Key);
      }
    });
    return docArray;
  }

  private makeDocumentsArrayForFolders(forLevel: number, folder: string, objectsArray: _Object[], forAll: boolean): Document[] {
    let docArray: Document[] = [];
    this.log("makeDocumentsArrayForFolders => called with level " + forLevel + " and folder " + folder + " and forAll " + forAll);
    const now: Date = new Date();
    objectsArray.forEach(object => {
      this.log("makeDocumentsArrayForFolders => Object key is: " + object.Key);
      let name: string = "unknown";
      let fullFileName = "unknown";
      let isFolder: boolean = false;
      let level: number = 0;
      if (object.Key !== undefined) {
        fullFileName = object.Key;
        name = this.extractDisplayName(object.Key);
        isFolder = this.isFolder(object.Key);
        level = this.identifyLevel(object.Key);
        this.log("makeDocumentsArrayForFolders => level is: " + level);
      }
      let lastModified: Date = now;
      if (object.LastModified !== undefined) {
        lastModified = object.LastModified;
      }
      const doc: Document = {
        displayName: name,
        fileName: fullFileName,
        isFolder: isFolder,
        level: level,
        modificationDate: lastModified
      }
      if (forAll) {
        this.log("makeDocumentsArrayForFolders => add document: " + JSON.stringify(doc));
        docArray.push(doc);
      } else if (!forAll && level === forLevel+1 && this.prefixMatches(level, folder, doc)) {
          this.log("makeDocumentsArrayForFolders => add document: " + JSON.stringify(doc));
          docArray.push(doc);
      } else this.log("makeDocumentsArrayForFolders => document not added: " + JSON.stringify(doc));
    })
    return docArray;
  }
  
  private extractDisplayName(key: string): string {
    let splitted: string[] = key.split("/");
    if (this.isFolder(key)) return splitted[splitted.length - 2];
    else return splitted[splitted.length - 1];
  }

  private isFolder(key: string): boolean {
    if (key.indexOf("/") === -1) return false;
    else if (key.lastIndexOf("/") !== key.length - 1) return false;
    else return true;
  }

  private identifyLevel(key: string): number {
    this.log("identifyLevel => for " + key);
    let splitted: string[] = key.split("/");
    /*splitted.forEach(element => {
      this.log("identifyLevel => key element after split " + element);
    });*/
    this.log("identifyLevel => splitted key length " + splitted.length);
    if (this.isFolder(key)) return splitted.length - 1;
    else return splitted.length;
  }

  private prefixMatches(level: number, prefix: string | undefined, doc: Document): boolean {
    this.log("prefixMatches => level '" + level + "' prefix '" + prefix + "' and doc fileName '" + doc.fileName + "' and is folder '" + doc.isFolder + "'")
    if (level === 1) return true
    else if (prefix) {
      let path: string
      if (doc.isFolder) {
        //path = doc.fileName.substring(0, doc.fileName.length-1)
        path = this.cutLastSlash(doc.fileName)
        path = this.retrieveParentDir(path)
      }
      else path = doc.fileName.substring(0, doc.fileName.lastIndexOf('/'))
      this.log("prefixMatches => path to compare '" + path + "'" )
      if (path === prefix) {
        this.log("prefixMatches => true")
        return true
      }
      else {
        this.log("prefixMatches => false")
        return false
      } 
    }
    return false;
  }

  public cutLastSlash(path: string): string { 
    if (path && path.endsWith('/')) {
      const lastIndexOfSlash: number = path.lastIndexOf('/')
      if (lastIndexOfSlash > 0) return path.substring(0, lastIndexOfSlash)
    }
    return path
  }

  public retrieveParentDir(path: string): string {
    this.log("retrieveParentDir => from " + path)
    let result: string = ""
    if (path) {
      if (path.endsWith('/')) {
        path = this.cutLastSlash(path)
      }
      const lastIndexOfSlash: number = path.lastIndexOf('/')
      if (lastIndexOfSlash > 0) {
        result = path.substring(0, lastIndexOfSlash)
      }
    }
    this.log("retrieveParentDir => result is '" + result + "'")
    return result
  }


  private log(message: string) {
    this.loggingService.log(`DocumentService: ${message}`);
  }

  private logError(errorMessage: string) {
    this.loggingService.logError(`DocumentService: ${errorMessage}`);
  }

  

}



