import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Document } from '../models/document.model';
import { faFile, faFileImage, faFilePdf, faFolder, faLevelUp } from '@fortawesome/free-solid-svg-icons';
import { Project } from '../models/project.model';
import { LoggingService } from '@inspiring-health/mea-commons';
import { DocumentService } from '../services/document.service';


export abstract class DocumentsBaseComponent {

    readonly folderIcon: IconDefinition = faFolder;
    readonly fileIcon: IconDefinition = faFile;
    readonly pdfFileIcon: IconDefinition = faFilePdf;
    readonly imageFileIcon: IconDefinition = faFileImage;
    readonly levelUpIcon: IconDefinition = faLevelUp;
    
    path: Document[] = [];
    documents: Document[] = [];

    constructor(protected loggingService: LoggingService, protected documentService: DocumentService) {

    }

    onSelectPath(project: Project, document: Document) {
      this.baselog("onSelectPath => for " + document.displayName)
      if (project && document.isFolder) {
        let i: number = this.path.indexOf(document);
        this.path = this.path.slice(0, i + 1);
        this.documentService.retrieveDocuments(this.path.length, project, this.getPath(project, this.path), false).subscribe(s => {
          this.documents = s;
        })
      } else {
        this.baselogError("onSelectPath project undefined or document in path is not a folder");
      }
    }

    onSelectRoot(project: Project) {
      this.path = [];
      this.retrieveDocuments(project);
    }


    protected sortByDefault() {
      this.documents?.sort((d1, d2) => 
        d1.displayName.localeCompare(d2.displayName)
      )
    }

    isImage(document: Document): boolean {
        let extension: string = this.retrieveLowerCaseExtension(document.displayName);
        if (extension === "jpg" || extension === "png" || extension === "gif") return true;
        else return false;
      }
    
    isPdf(document: Document): boolean {
      let extension: string = this.retrieveLowerCaseExtension(document.displayName);
      if (extension === "pdf") return true;
      else return false;
    }
  
    isAny(document: Document): boolean {
      if (document.isFolder || this.isImage(document) || this.isPdf(document)) return false;
      else return true;
    }

    protected retrieveDocuments(project: Project): void {
      this.baselog("retrieveDocuments => called");
      if (project) {
        let folder: string = project.subProject
        if (project.hasFolders) folder = this.getPath(project, this.path)
        this.baselog("retrieveDocuments => subscribe to service call");
        this.documentService.retrieveDocuments(this.path.length, project, folder, false).subscribe(s => {
          this.baselog("retrieveDocuments => documents: " + JSON.stringify(s))
          this.documents = s;
          this.sortByDefault();
        });
      }
    }

    private retrieveLowerCaseExtension(fileName: string): string {
      let index: number = fileName.lastIndexOf('.');
      if (index > 0) {
        let extension: string = fileName.substring(index + 1);
        return extension.toLowerCase();
      } else {
        return "";
      }
    }

    protected getPath(project: Project, path: Document[]): string {
      let pathStr: string = "";
      if (project) {
        if (project.hasFolders && path.length > 0) {
          pathStr = path[path.length - 1].fileName;
          pathStr = pathStr.substring(0, pathStr.lastIndexOf('/'));
        }
        else pathStr = project.subProject;
      }
      this.baselog("getPath => path: " + pathStr);
      return pathStr;
    }
  
    private baselog(message: string) {
      this.loggingService.log(`DocumentBaseComponent: ${message}`);
    }

    private baselogError(errorMessage: string) {
      this.loggingService.logError(`DocumentBaseComponent: ${errorMessage}`);
    }
}