import { Component, Directive, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowsAlt, faDownload, faEdit, faFolderPlus, faTrashAlt, faUpload } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';

import { ConfirmationDialogComponent, LoggingService, ToastService } from '@inspiring-health/mea-commons';

import { Document } from '../../models/document.model';
import { Project } from '../../models/project.model';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, concatMap, exhaustMap, from, of, switchMap, tap, toArray } from 'rxjs';
import { DocumentService } from '../../services/document.service';
import { DocumentsBaseComponent } from '../../shared/documents-base.component';
import { FolderDialogComponent } from '../folder-dialog/folder-dialog.component';
import { MoveDialogComponent } from '../move-dialog/move-dialog.component';
import { OverwriteDialogComponent } from '../overwrite-dialog/overwrite-dialog.component';
import { RenameDialogComponent } from '../rename-dialog/rename-dialog.component';

export type DocumentSortColumn = keyof Document | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { 'asc': 'desc', 'desc': '', '': 'asc' };

const compare = (v1: string | Date | number | boolean, v2: string | Date | number | boolean) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface DocumentsSortEvent {
  column: DocumentSortColumn;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortabledocument]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})

export class DocumentsSortableHeader {

  @Input() sortabledocument: DocumentSortColumn = 'modificationDate';
  @Input() direction: SortDirection = 'desc';
  @Output() sortDocument = new EventEmitter<DocumentsSortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sortDocument.emit({ column: this.sortabledocument, direction: this.direction });
  }
}



@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent extends DocumentsBaseComponent implements OnInit {

  loading: boolean = false;
  fileName: string = '';
  file?: File;

  @Input({required: true}) project?: Project;

  readonly deleteIcon: IconDefinition = faTrashAlt;
  readonly uploadIcon: IconDefinition = faUpload;
  readonly downloadIcon: IconDefinition = faDownload;
  readonly folderPlusIcon: IconDefinition = faFolderPlus;
  readonly renameIcon: IconDefinition = faEdit;
  readonly moveIcon: IconDefinition = faArrowsAlt;
  

  @ViewChildren(DocumentsSortableHeader) headers?: QueryList<DocumentsSortableHeader>;
  

  constructor(loggingService: LoggingService, documentService: DocumentService,
    private modalService: NgbModal, public translate: TranslateService, private toastService: ToastService) {
      super(loggingService, documentService)
  }

  ngOnInit(): void {
    this.log("ngOnInit selected project: " + this.project?.name);
    if (this.project) {
      this.documentService.retrieveDocuments(this.path.length, this.project, this.project.subProject, false).subscribe(s => {
        this.documents = s;
        this.sortByDefault();
      });
    }
  }

  
  ngOnChanges() {
    this.log("ngOnChanges selected project: " + this.project?.name);
    this.path = [];
    this.retrieveDocuments(this.project!);
  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
      this.log("onFileSelected: " + this.fileName);
    }
  }

  

  onSort({ column, direction }: DocumentsSortEvent) {
    // resetting other headers
    if (this.headers !== undefined) {
      this.headers.forEach(header => {
        if (header.sortabledocument !== column) {
          header.direction = '';
        }
      });
    }
    // sorting documents
    if (direction === '' || column === '') {
      this.retrieveDocuments(this.project!);
    } else {
      let folder: Document[] = [];
      let files: Document[] = [];
      this.documents.forEach(d => {
        if (d.isFolder) {
          folder.push(d);
        } else {
          files.push(d);
        }
      });
      folder.sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
      files.sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
      this.documents = folder.concat(files);
    }
  }

  onClick(document: Document) {
    if (!document.isFolder) {
      this.download(document);
    } else {
      this.listSubDir(document);
    }
  }

  upload() {
    this.log("upload => called for " + this.fileName);
    if (this.file !== undefined && this.project !== undefined) {
      this.documentService.uploadDokument(this.path.length, this.project, this.getPath(this.project, this.path), this.file).subscribe(s => {
        this.documents = s;
        this.fileName = '';
      });
    }
  }

  overwrite(toOverwrite: Document, overwriteWith: Document, currentPath: Document[], originalDoc: Document) {
    this.log("overwrite => called with " + JSON.stringify(toOverwrite) + " to overwrite with " + JSON.stringify(overwriteWith))
    this.log("overwrite => called with currentPath: " + JSON.stringify(currentPath))
    const modalRef = this.modalService.open(OverwriteDialogComponent)
    modalRef.componentInstance.toOverwrite = toOverwrite
    modalRef.componentInstance.overwriteWith = overwriteWith
    modalRef.componentInstance.currentPath = Array.from(currentPath)

    this.translate.get('DOCUMENTS.OVERWRITEDIALOG.CANCELBUTTON').subscribe(translation => {
      modalRef.componentInstance.cancelButtonText = translation
    })
    this.translate.get('DOCUMENTS.OVERWRITEDIALOG.OKBUTTON').subscribe(translation => {
      modalRef.componentInstance.okButtonText = translation
    })
    this.translate.get('DOCUMENTS.OVERWRITEDIALOG.NOBUTTON').subscribe(translation => {
      modalRef.componentInstance.noButtonText = translation
    })
    this.translate.get('DOCUMENTS.OVERWRITEDIALOG.TITLE', { documentname: overwriteWith.displayName }).subscribe(translation => {
      modalRef.componentInstance.title = translation
    })
    //set result handlers
    modalRef.result.then((result) => {
      this.log("overwriteDialog => closed with " + result)
      //OK_CLICK or NO_CLICK
      if (result === 'NO_CLICK') {
        this.appendNextVersion(overwriteWith)
      }
      const currentPath: Document[] = modalRef.componentInstance.currentPath
      this.log("overwriteDialog => path: " + JSON.stringify(currentPath))
      const targetFolder = currentPath[currentPath.length-1]
      this.log("overwriteDialog => targetFolder: " + JSON.stringify(targetFolder))
      if (!overwriteWith.isFolder) {
        this.moveDocumentHandler(targetFolder, overwriteWith, originalDoc);
      } else {
        this.moveFolderHandler(targetFolder, overwriteWith, originalDoc);
      }
    }, (reason) => { this.log("overwriteDialog => closed with " + reason); }
    );
  }

  private appendNextVersion(doc: Document) {
    this.log("appendNextVersion => input: " + JSON.stringify(doc))
    if (doc.isFolder) {
      let name = this.cutFileName(doc.fileName)
      doc.fileName = name + "_1/"
      doc.displayName = doc.displayName + "_1"
    } else {
      const urlRegexp = /([\w-]+)\.[a-zA-Z]{3}/g
      let match : RegExpMatchArray | null = doc.displayName.match(urlRegexp)
      if (match) {
        const extension = doc.displayName.slice(-3)
        const withoutExtension = doc.displayName.slice(0, -4)
        doc.displayName = withoutExtension + "_1." + extension
        const fileNameWithoutExtension = doc.fileName.slice(0, -4)
        doc.fileName = fileNameWithoutExtension + "_1." + extension
      } else {
        doc.fileName = doc.fileName + "_1"
        doc.displayName = doc.displayName + "_1"
      }
    }
    this.log("appendNextVersion => changed names are " + JSON.stringify(doc))
  }

  move(doc: Document) {
    this.log("move => clicked for file name " + doc.fileName);
    const modalRef = this.modalService.open(MoveDialogComponent)
    const copiedDoc: Document = { ...doc}
    modalRef.componentInstance.documents = undefined
    modalRef.componentInstance.documentToMove = copiedDoc
    modalRef.componentInstance.targetFolder = this.path.at(-1)
    modalRef.componentInstance.project = this.project
    modalRef.componentInstance.path = Array.from(this.path)
    this.translate.get('DOCUMENTS.MOVEDIALOG.NAME').subscribe(translation => {
      modalRef.componentInstance.label = translation
    })
    this.translate.get('DOCUMENTS.MOVEDIALOG.SELECTTARGET').subscribe(translation => {
      modalRef.componentInstance.selecttarget = translation
    })
    this.translate.get('DOCUMENTS.MOVEDIALOG.CANCELBUTTON').subscribe(translation => {
      modalRef.componentInstance.cancelButtonText = translation
    })
    this.translate.get('DOCUMENTS.MOVEDIALOG.OKBUTTON').subscribe(translation => {
      modalRef.componentInstance.okButtonText = translation
    })
    this.translate.get('DOCUMENTS.MOVEDIALOG.TITLE', { documentname: copiedDoc.displayName }).subscribe(translation => {
      modalRef.componentInstance.title = translation
    })
    //set result handlers
    modalRef.result.then(() => {
      this.log("moveDialog => closed with yes");
      let targetFolderNameWithoutSlash: string = ""
      if (modalRef.componentInstance.targetFolder) {
        targetFolderNameWithoutSlash = this.documentService.cutLastSlash(modalRef.componentInstance.targetFolder.fileName)
      } else {
        const rootDir: Document = {
          displayName: "",
          fileName: "",
          isFolder: true,
          level: 0,
          modificationDate: new Date()
        } 
        modalRef.componentInstance.targetFolder = rootDir
      }
      if (targetFolderNameWithoutSlash === this.documentService.retrieveParentDir(doc.fileName)) {
        this.translate.get('DOCUMENTS.MOVEDIALOG.ERRORFILEISSAME').subscribe(translation => {
          this.logError("moveFolderHandler => " + translation);
          this.toastService.showError(translation);
        });
      } else if (modalRef.componentInstance.targetFolder.fileName.localeCompare(doc.fileName) === 0) {
        this.translate.get('DOCUMENTS.MOVEDIALOG.ERRORINTOSELF').subscribe(translation => {
          this.logError("moveFolderHandler => " + translation);
          this.toastService.showError(translation);
        });
      } else if (this.project !== undefined) {
        this.log("moveDialog => doc to move attributes: " + JSON.stringify(modalRef.componentInstance.documentToMove));
        this.log("moveDialog => target folder: " + JSON.stringify(modalRef.componentInstance.targetFolder))
        const docToOverwrite = this.isSameNameInDocs(modalRef.componentInstance.documentToMove, modalRef.componentInstance.documents)
        if (modalRef.componentInstance.targetFolder && 
          modalRef.componentInstance.documentToMove.displayName === modalRef.componentInstance.targetFolder.displayName) {
          this.overwrite(modalRef.componentInstance.targetFolder, copiedDoc, modalRef.componentInstance.path, doc)
        } else if (docToOverwrite) {
          this.overwrite(docToOverwrite, copiedDoc, modalRef.componentInstance.path, doc)
        }else {
          let currentPath: Document[] = modalRef.componentInstance.path
          this.log("moveDialog => path : " + JSON.stringify(currentPath))
          if (!doc.isFolder) {
            this.moveDocumentHandler(modalRef.componentInstance.targetFolder, copiedDoc, doc);
          } else {
            this.moveFolderHandler(modalRef.componentInstance.targetFolder, copiedDoc, doc);
          }
        }
      }
    }, () => { this.log("moveDialog => closed with no"); }
    );
  }

  private isSameNameInDocs(doc: Document, docs: Document[]): Document|undefined {
    let docWithSameName: Document|undefined = undefined
    docs.forEach((d:Document) => {
      if (doc.displayName === d.displayName) docWithSameName = d
    });
    return docWithSameName
  }

  private moveDocumentHandler(targetFolder: Document, doc: Document, originalDoc: Document) {
    //if targetFolder is undefined move to root is executed
    this.loading = true
    let targetFolderName: string = ""
    let targetFolderLevel: number = 0
    if (targetFolder) {
      targetFolderName = targetFolder.fileName
      targetFolderLevel = targetFolder.level
    }
    this.log("moveDocumentHandler => target folder name: " + targetFolderName);
    this.log("moveDocumentHandler => target folder level: " + targetFolderLevel);
    this.log("moveDocumentHandler => document name: " + doc.fileName);
    this.log("moveDocumentHandler => original document name: " + originalDoc.fileName);
    this.documentService.copyDocument(
      targetFolderLevel, this.project!, originalDoc.fileName, targetFolderName, doc.displayName).pipe(
        exhaustMap(() => {
          return this.documentService.deleteDocument(originalDoc.level, this.project!, originalDoc);
        }), exhaustMap(() => {
          this.log("moveDocumentHandler => document deleted")
          if (this.path.length > 0) {
            return this.documentService.retrieveDocuments(this.path.length, this.project!, this.path[this.path.length-1].fileName, false)
          }
          else {
            return this.documentService.retrieveDocuments(0, this.project!, this.project!.subProject, false) 
          }
        })).subscribe({
          next: docs => {
            this.log("moveDocumentHandler => docs retrieved after copy and delete: " + JSON.stringify(docs))
            this.documents = docs
            this.sortByDefault();
            this.log("moveDocumentHandler => docs after sortByDefault: " + JSON.stringify(this.documents))
          },
          error: error => {
            this.logError("moveDocumentHandler => error: " + error.message)
          },
          complete: () => {
            this.log("moveDocumentHandler => completed")
            this.loading = false
          }
        });
  }

  private moveFolderHandler(targetFolder: Document, folder: Document, originalFolder: Document) {
    this.loading = true;
    this.log("moveFolderHandler => called with: " + folder.fileName + " to target " + targetFolder.fileName);
    this.log("moveFolderHandler => folder name: " + folder.fileName);
    this.log("moveFolderHandler => called with original folder: " + originalFolder.fileName);

    this.moveFolder(folder, targetFolder, originalFolder).pipe(exhaustMap(() => {
      this.log("moveFolderHandler => delete folder " + originalFolder.fileName)
      return this.documentService.deleteDocument(originalFolder.level, this.project!, originalFolder)
    }), exhaustMap(() => {
      this.log("moveFolderHandler => folder deleted")
      if (this.path.length > 0) {
        return this.documentService.retrieveDocuments(this.path.length, this.project!, this.path[this.path.length-1].fileName, false)
      }
      else {
        return this.documentService.retrieveDocuments(0, this.project!, this.project!.subProject, false) 
      }
    })).subscribe({
      next: docs => {
        this.log("moveFolderHandler => docs retrieved after copy and delete: " + JSON.stringify(docs))
        this.documents = docs
        this.sortByDefault();
      },
      error: error => {
        this.logError("moveFolderHandler => error: " + error.message)
      },
      complete: () => {
        this.log("moveFolderHandler => completed")
        this.loading = false
      }
    })
  }

  private moveFolder(folder: Document, targetFolder: Document, originalFolder: Document): Observable<Document[]> {
    this.log("moveFolder => called with doc: '" + JSON.stringify(folder) + "' and target " + JSON.stringify(targetFolder))
    //if targetFolder is undefined move to root is executed
    let targetFolderName: string = ""
    let targetFolderLevel: number = 0
    if (targetFolder) {
      targetFolderName = targetFolder.fileName
      targetFolderLevel = targetFolder.level
    }
    this.log("moveFolder => target folder name: " + targetFolderName);
    this.log("moveFolder => target folder level: " + targetFolderLevel);
    const newFolder: Document = {
      displayName: folder.displayName,
      fileName: targetFolderName + folder.displayName + '/',
      isFolder: true,
      level: targetFolderLevel + 1,
      modificationDate: new Date()
    }
    this.log("moveFolder => create new folder: " + JSON.stringify(newFolder))
    return this.documentService.createFolder(newFolder.level, this.project!, newFolder).pipe(
      exhaustMap((cf: Document[]) => {
        this.log("moveFolder => new created folder: " + JSON.stringify(cf))
        return this.documentService.retrieveDocuments(originalFolder.level, this.project!, originalFolder.fileName, false)
      }), exhaustMap((docs: Document[]) => {
        this.log("moveFolder => docs retrieved in folder to move: " + JSON.stringify(docs))
        if (!docs || docs.length === 0) {
          //If originalFolder is empty we have finished, so delete it
          this.log("moveFolder => delete original folder cause no further docs to handle" + originalFolder.fileName)
          return this.documentService.deleteDocument(originalFolder.level, this.project!, originalFolder).pipe(
            exhaustMap(() => {
              this.log("moveFolder => original folder deleted")
              return docs
            })
          )
        } else {
          //Handle dirs first
          docs.sort((d1, d2) => {
            if (d1.isFolder === d2.isFolder) return 0
            else if (d1.isFolder) return -1
            else return 1
          })
        }
        return from(docs)
      }), concatMap((doc: Document) => {
        this.log("moveFolder => doc from docs retrieved: " + JSON.stringify(doc))
        this.log("moveFolder => doc filename : " + doc.fileName)
        if (doc && doc.fileName.localeCompare(originalFolder.fileName) !== 0) {
          this.log("moveFolder => handle doc: " + doc.fileName)
          if (!doc.isFolder) {
            this.log("moveFolder => copy doc " + doc.fileName + " to folder " + newFolder.fileName)
            return this.documentService.copyDocument(doc.level, this.project!, doc.fileName, newFolder.fileName, doc.displayName).pipe(
                exhaustMap(() => {
                  this.log("moveFolder => delete doc " + doc.fileName)
                  return this.documentService.deleteDocument(doc.level, this.project!, doc)
                }), exhaustMap(()=> {
                  return of(doc)
                }))
          } else { //doc is folder
            this.log("moveFolder => call moveFolder (recursive) with doc " + doc.fileName + " target " + newFolder.fileName)
            //In this case originalFolder === doc
            return this.moveFolder(doc, newFolder, doc).pipe(exhaustMap(() => {
              this.log("moveFolder => recursive call finished")
              return of(doc)
            }))
          }
        } else {
          this.log("moveFolder => return unhandled doc")
          return of(doc)
        }
      }),
      tap(d => this.log("moveFolder => " + d.fileName + " moved")),
      toArray()
    )
  }

  rename(doc: Document) {
    this.log("rename => clicked for " + doc.displayName + " and file name " + doc.fileName);
    const modalRef = this.modalService.open(RenameDialogComponent)
    modalRef.componentInstance.document = {
      displayName: doc.displayName,
      fileName: doc.fileName,
      isFolder: doc.isFolder,
      level: doc.level,
      modificationDate: doc.modificationDate
    }
    this.translate.get('DOCUMENTS.RENAMEDIALOG.NAME').subscribe(translation => {
      modalRef.componentInstance.label = translation
    })
    this.translate.get('DOCUMENTS.RENAMEDIALOG.CANCELBUTTON').subscribe(translation => {
      modalRef.componentInstance.cancelButtonText = translation
    })
    this.translate.get('DOCUMENTS.RENAMEDIALOG.OKBUTTON').subscribe(translation => {
      modalRef.componentInstance.okButtonText = translation
    })
    this.translate.get('DOCUMENTS.RENAMEDIALOG.TITLE').subscribe(translation => {
      modalRef.componentInstance.title = translation
    })
    //set result handlers
    if (!doc.isFolder) {
      this.renameDocumentHandler(modalRef, doc);
    } else {
      this.renameFolderHandler(modalRef, doc);
    }
  }

  private renameFolderHandler(modalRef: NgbModalRef, folder: Document) {
    this.loading = true;
    this.log("renameFolderHandler => called with: " + folder.fileName);
    modalRef.result.then(() => {
      this.log("renameFolderHandler => closed with yes");
      const newFolderName: string = modalRef.componentInstance.document.displayName
      // displayName is undefined
      this.log("renameFolderHandler => new name is " + newFolderName);
      this.log("renameFolderHandler => old folder file name is " + folder.fileName);
      const folderLevel: number = folder.level
      this.log("renameFolderHandler => folder level is " + folderLevel);
      const newFolderPath: string = this.replaceFolderName(folder.fileName, newFolderName)
      this.log("renameFolderHandler => new folder path is " + newFolderPath);
      if (this.project !== undefined) {
        this.log("renameFolderHandler => project is " + this.project.name);
        this.documentService.retrieveDocuments(folderLevel, this.project, folder.fileName, true).pipe(
          exhaustMap((docs: Document[]) => { 
            this.log("renameFolderHandler => all docs to handle: " + JSON.stringify(docs))
            docs.sort((d1, d2) => {
              if (d1.isFolder === d2.isFolder) return 0
              else if (d1.isFolder) return -1
              else return 1
            })
            return from(docs)
          }), concatMap(doc => {
          this.log("renameFolderHandler => ------------")
          this.log("renameFolderHandler => handle doc: " + JSON.stringify(doc))
          const newFilePath: string = this.replacePathInFileName(doc.fileName, folder.fileName, newFolderPath)
          this.log("renameFolderHandler => new path for doc is " + newFilePath)
          const newFolder: Document = {
            displayName: doc.displayName,
            isFolder: true,
            level: doc.level,
            fileName: newFilePath,
            modificationDate: new Date()
          }
          if (doc.isFolder) {
            if (doc.level === folderLevel) {
              newFolder.fileName = newFolderPath
            }
            this.log("renameFolderHandler => the folder to rename: " + JSON.stringify(newFolder))
            return this.documentService.createFolder(newFolder.level-1, this.project!, newFolder).pipe(
              concatMap(() => this.documentService.deleteDocument(doc.level-1, this.project!, doc))
            )
          } else {
            const newPathForFile: string = this.cutFileName(newFilePath)
            this.log("renameFolderHandler => new path for doc is " + newFilePath);
            return this.documentService.copyDocument(doc.level, this.project!, doc.fileName, newPathForFile, doc.displayName).pipe(
              concatMap(() => this.documentService.deleteDocument(folderLevel-1, this.project!, doc))
            )
          }
        }), switchMap((d) => {
          return this.documentService.retrieveDocuments(folderLevel-1, this.project!, this.getPath(this.project!, this.path), false)
        })).subscribe({
          next: docs => {
            this.log("renameFolderHandler => docs after copy and delete: " + JSON.stringify(docs))
            this.documents = docs
          },
          error: error => {
            this.logError("renameFolderHandler => error: " + error.message)
          },
          complete: () => {
            this.log("renameFolderHandler => completed")
            this.loading = false;
          }
        });
      }
    }, () => { 
      this.log("renameFolderHandler => closed with no"); 
      this.loading = false;
    })
  }

  private cutFileName(fullFileName: string): string {
    const lastIndexOfSlash: number = fullFileName.lastIndexOf('/')
    return fullFileName.substring(0, lastIndexOfSlash)
  }

  
  private replaceFolderName(path: string, newFolder: string): string {
    const withoutLastSlash: string = path.substring(0, path.length-1)
    this.log("replaceFolderName => without last slash " + withoutLastSlash)
    const lastIndexOfSlash: number = withoutLastSlash.lastIndexOf('/')
    this.log("replaceFolderName => last idx of slash " + lastIndexOfSlash)
    if (lastIndexOfSlash === -1) return newFolder
    const newPath = path.substring(0, lastIndexOfSlash + 1) + newFolder
    this.log("replaceFolderName => new path " + newPath)
    return newPath
  }

  private replacePathInFileName(fileNamePath: string, oldFolderPath: string, newFolderPath: string): string {
    this.log("replacePathInFileName => called with fileNamePath " + fileNamePath + " oldFolderPath " + oldFolderPath + " newFolderPath " + newFolderPath)
    const newFileNamePath: string = fileNamePath.replace(oldFolderPath, newFolderPath+'/')
    this.log("replacePathInFileName => new file name is " + newFileNamePath)
    return newFileNamePath
  }

  //Result handler
  private renameDocumentHandler(modalRef: NgbModalRef, doc: Document) {
    this.loading = true;
    modalRef.result.then(() => {
      this.log("renameDocumentHandler => closed with yes");
      this.renameDocument(doc, modalRef.componentInstance.document.displayName).subscribe({
          next: docs => {
            this.log("renameDocumentHandler => docs after copy and delete: " + JSON.stringify(docs))
            this.documents = docs
          },
          error: error => {
            this.logError("renameDocumentHandler => error: " + error.message)
          },
          complete: () => {
            this.log("renameDocumentHandler => completed")
            this.loading = false;
          }
        });
    }, () => { 
      this.log("renameDialog => closed with no"); 
      this.loading = false;
    }
    );
  }

  private renameDocument(doc: Document, name: string): Observable<Document[]>{
    if (this.project !== undefined) {
      this.log("renameDocument => with doc attributes: " + JSON.stringify(doc) + " and name " + name);
      return this.documentService.copyDocument(
        doc.level, this.project, doc.fileName, this.cutFileName(doc.fileName), name).pipe(
        exhaustMap(() => {
          this.log("renameDocument => with doc copied now delete old");
          return this.documentService.deleteDocument(doc.level - 1, this.project!, doc);
        })
      );
    } else {
      return EMPTY
    }
  }

  newFolder() {
    this.log("newFolder => clicked");
    const modalRef = this.modalService.open(FolderDialogComponent);
    const folder: Document = {
      displayName: "",
      fileName: "",
      isFolder: true,
      level: this.path.length + 1,
      modificationDate: new Date()
    }
    this.translate.get('DOCUMENTS.FOLDERDIALOG.FOLDERNAME').subscribe(translation => {
      modalRef.componentInstance.label = translation;
    });
    this.translate.get('DOCUMENTS.FOLDERDIALOG.CANCELBUTTON').subscribe(translation => {
      modalRef.componentInstance.cancelButtonText = translation;
    });
    this.translate.get('DOCUMENTS.FOLDERDIALOG.OKBUTTON').subscribe(translation => {
      modalRef.componentInstance.okButtonText = translation;
    });
    this.translate.get('DOCUMENTS.FOLDERDIALOG.CREATETITLE').subscribe(translation => {
      modalRef.componentInstance.title = translation;
      folder.displayName = this.translate.instant("DOCUMENTS.FOLDERDIALOG.DEFAULTNAME");
      modalRef.componentInstance.folder = folder;
    });
    //Result handler
    modalRef.result.then(() => {
      this.log("newFolder => open result handler: confirm => closed with yes");
      this.log("newFolder => open result handler: local folder name is " + folder.displayName);
      if (this.documentService.isValidObjectKey(folder.displayName)) {
        this.log("newFolder => open result handler: Create folder");
        let path = this.getPath(this.project!, this.path);
        if (path.length == 0) folder.fileName = folder.displayName;
        else folder.fileName = path + "/" + folder.displayName;
        this.log("newFolder => folder to create: " + JSON.stringify(folder));
        this.documentService.createFolder(this.path.length, this.project!, folder).subscribe(docs => {
          this.documents = docs;
        });
      } else {
        this.translate.get('DOCUMENTS.ERRORTOAST.TITLE').subscribe(translation => {
          this.logError("newFolder => " + translation);
          let text: string = this.translate.instant('DOCUMENTS.ERRORTOAST.TEXT')
          this.toastService.showErrorCloseOnCklick(text, translation);
        });
      }
    }, () => {
      this.log("newFolder => closed with no");
    });
  }


  confirmDeleteFolder(folderToDelete: Document) {
    this.log("confirmDeleteFolder => called for " + folderToDelete.displayName);
    if (this.project) {
      this.documentService.folderHasContent(this.project, folderToDelete).subscribe(b => {
        if (b) {
          this.translate.get('DOCUMENTS.DELETEFOLDERERRORDIALOG.TEXT', { foldername: folderToDelete.displayName }).subscribe(translation => {
            this.toastService.showError(translation);
          });
        } else {
          this.confirmDelete(folderToDelete);
        }
      });

    }
  }

  confirmDelete(docToDelete: Document) {
    this.log("confirmDelete => called for " + docToDelete.displayName);
    const modalRef = this.modalService.open(ConfirmationDialogComponent);

    this.translate.get('DOCUMENTS.CONFIRMATION.TITLE').subscribe(translation => {
      modalRef.componentInstance.title = translation;
    });
    this.translate.get('DOCUMENTS.CONFIRMATION.QUESTION', { filename: docToDelete.displayName }).subscribe(translation => {
      modalRef.componentInstance.text = translation;
    });
    this.translate.get('DOCUMENTS.CONFIRMATION.NO').subscribe(translation => {
      modalRef.componentInstance.cancelButtonText = translation;
    });
    this.translate.get('DOCUMENTS.CONFIRMATION.YES').subscribe(translation => {
      modalRef.componentInstance.okButtonText = translation;
    });

    //set result handlers
    modalRef.result.then(() => {
      this.log("confirmDelete => closed with yes");
      if (this.project !== undefined) {
        this.documentService.deleteDocument(this.path.length, this.project, docToDelete).subscribe(s => this.documents = s);
      }
    }, () => { this.log("confirmDelete => closed with no"); }
    );

  }

  download(doc: Document) {
    this.log("download => called for " + doc.displayName);
    if (this.project !== undefined) {
      return this.documentService.downloadDokument(this.project, doc).then(stream => {
        const reader = stream.getReader();
        return new ReadableStream({
          start(controller) {
            // The following function handles each data chunk
            function push() {
              reader.read().then(({ done, value }) => {
                // If there is no more data to read
                if (done) {
                  //console.log('download => push done value: ', done);
                  controller.close();
                  return;
                }
                // Get the data and send it to the browser via the controller
                controller.enqueue(value);
                // Check chunks by logging to the console
                //console.log("download => push chunk: ", value);
                push();
              })
            }
            push();
          }
        });
      }).then(newstream => {
        // Respond with our stream
        return new Response(newstream, { headers: { "Content-Type": "application/octet-stream" } }).blob();
      }).then(result => {
        this.log("download => Response: " + result);
        saveAs(result, doc.displayName);
      });
    }
    return;
  }

  protected sortByDefault() {
    let column: DocumentSortColumn = "modificationDate";
    let direction: SortDirection = "desc";
    let event: DocumentsSortEvent = { column, direction };
    this.log("sortByDefault => calling onSort");
    this.onSort(event);
  }


  

  private listSubDir(document: Document) {
    this.log("listSubDir => for " + document.displayName)
    if (document.isFolder && this.project) {
      if (this.path.length === 0 || this.path[this.path.length - 1].fileName !== document.fileName) {
        this.path.push(document);
        this.documentService.retrieveDocuments(this.path.length, this.project, this.getPath(this.project, this.path), false).subscribe(s => {
          this.log("listSubDir => documents " + JSON.stringify(s))
          this.documents = s;
          this.sortByDefault();
        });
      }
    } else {
      this.logError("listSubDir has to be called with folder document!");
    }
  }

  protected log(message: string) {
    this.loggingService.log(`DocumentComponent: ${message}`);
  }

  protected logError(errorMessage: string) {
    this.loggingService.logError(`DocumentComponent: ${errorMessage}`);
  }

}


